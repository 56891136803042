import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { setUser } from '@sentry/angular-ivy';
import { assign, omit } from 'lodash';
import { Observable, Subject, catchError, map, tap } from 'rxjs';
import { BusinessDocuments } from '../../../common/interfaces/documents/business-documents';
import { ApiService } from '../../../common/services/api.service';
import { DashboardPermission } from '../../../dashboard/common/enums/dashboard-permission';
import { Business } from '../../../dashboard/management-panel/company-data/common/interfaces/business';
import { AuthRoute } from '../../auth.routes';
import { ForgotPasswordForm } from '../../forgot-password/forgot-password.component';
import { JoinForm } from '../../join/join.component';
import { LoginForm } from '../../login/login.component';
import { RegisterCompanyForm } from '../../register-company/register-company.component';
import { RegisterForm } from '../../register/register.component';
import { ResetPasswordForm } from '../../reset-password/reset-password.component';
import { SupportForm } from '../../support/support.component';
import { AUTH_SENSITIVE_KEYS, AuthStorageKey } from '../enums/auth-storage-key';
import { AUTH_INTERCEPTOR_NAMESPACE_HEADER } from '../interceptors/auth.interceptor';
import { AuthData } from '../interfaces/auth-data';
import { User } from '../interfaces/user';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends ApiService {
  private _companyName = signal<string | null>(null);
  public get companyName() {
    return this._companyName.asReadonly();
  }

  private _token = signal<string | null>(null);
  public get token() {
    return this._token.asReadonly();
  }

  private _user = signal<User | null>(null);
  public get user() {
    return this._user.asReadonly();
  }

  private _business = signal<Business | null>(null);
  public get business() {
    return this._business.asReadonly();
  }

  private permissions = signal<DashboardPermission[] | null>(null);

  private _loggedOut$ = new Subject<void>();
  public get loggedOut$() {
    return this._loggedOut$.asObservable();
  }

  constructor(
    protected override http: HttpClient,
    private router: Router,
    private translateService: TranslateService,
  ) {
    super(http);
    this.loadData();
  }

  public login(form: LoginForm): Observable<void> {
    const companyName = form.companyName;
    const body = omit(form, 'companyName');

    return this.post<AuthData>('/login', body, {
      headers: {
        ...(companyName && {
          [AUTH_INTERCEPTOR_NAMESPACE_HEADER]: companyName,
        }),
      },
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 0) return this.post<AuthData>('/login', body);
        throw error;
      }),
      tap((data) => {
        this.saveData(data, companyName);
        this.translateService.use(data.user.interfaceLanguage);
      }),
      map(() => undefined),
    );
  }

  public activateAccount(token: string): Observable<void> {
    return this.post<AuthData>(`/activate-account/${token}`, undefined).pipe(
      tap((data) => this.saveData(data, null)),
      map(() => undefined),
    );
  }

  public register(form: RegisterForm): Observable<void> {
    return this.post('/register', form);
  }

  public registerBusinessData(form: RegisterCompanyForm): Observable<void> {
    return this.post<Business>('/complete-business-data', form).pipe(
      tap((business) => this.setBusiness(business)),
      map(() => undefined),
    );
  }

  public joinCompany(
    form: JoinForm,
    companyId: string,
    token: string,
  ): Observable<void> {
    return this.put<AuthData>(`/employees/activate/${token}`, form, {
      headers: { [AUTH_INTERCEPTOR_NAMESPACE_HEADER]: companyId },
    }).pipe(map(() => undefined));
  }

  public registerDocuments(
    form: Omit<BusinessDocuments, 'status'>,
  ): Observable<void> {
    return this.post<BusinessDocuments>('/upload-documents', form).pipe(
      tap((documents) => {
        const business = { ...this._business()!, documents };
        this.setBusiness(business);
      }),
      map(() => undefined),
    );
  }

  public sendContactMessage(form: SupportForm): Observable<void> {
    return this.post<void>('/send-contact-message', form);
  }

  public logout(): void {
    this.clearData();
    this._loggedOut$.next();
    this.router.navigateByUrl(AuthRoute.LOGIN);
  }

  public recoverPassword(form: ForgotPasswordForm): Observable<void> {
    const companyName = form.companyName;
    const body = omit(form, 'companyName');

    return this.post('/recover-password', body, {
      headers: { [AUTH_INTERCEPTOR_NAMESPACE_HEADER]: companyName },
    });
  }

  public resetPassword(
    form: ResetPasswordForm,
    token: string,
    companyId: string,
  ): Observable<void> {
    return this.post(`/reset-password/${token}`, form, {
      headers: {
        ...(companyId && {
          [AUTH_INTERCEPTOR_NAMESPACE_HEADER]: companyId,
        }),
      },
    });
  }

  public hasPermission(permission: DashboardPermission): boolean {
    const permissions = this.permissions() ?? [];
    return permissions.includes(permission);
  }

  public setCompanyName(companyName: string): void {
    this._companyName.set(companyName);
    localStorage.setItem(AuthStorageKey.COMPANY_NAME, this._companyName()!);
  }

  public setUser(user: User | null): void {
    this._user.set(user);
    if (user) localStorage.setItem(AuthStorageKey.USER, JSON.stringify(user));

    setUser(
      user
        ? {
            id: user.uuid,
            email: user.email,
            username: `${user.firstName} ${user.lastName}`,
            roles: user.roles.map((role) => role.value),
            business: this._companyName(),
          }
        : null,
    );
  }

  public setBusiness(business: Partial<Business>): void {
    this._business.update((current) => assign(current, business));
    localStorage.setItem(
      AuthStorageKey.BUSINESS,
      JSON.stringify(this._business()),
    );
  }

  public updateDocuments(documents: BusinessDocuments): void {
    this._business.update((business) => ({
      ...business!,
      documents,
    }));
  }

  private loadData(): void {
    const companyName = localStorage.getItem(AuthStorageKey.COMPANY_NAME);
    this._companyName.set(companyName);

    const [token, user, business, permissions] = [
      localStorage.getItem(AuthStorageKey.TOKEN),
      localStorage.getItem(AuthStorageKey.USER),
      localStorage.getItem(AuthStorageKey.BUSINESS),
      localStorage.getItem(AuthStorageKey.PERMISSIONS),
    ];
    if (!token || !user || !permissions) return;

    this._token.set(token);
    this.setUser(JSON.parse(user));
    this._business.set(business ? JSON.parse(business) : null);
    this.permissions.set(JSON.parse(permissions));
  }

  private saveData(authData: AuthData, companyName: string | null): void {
    this._companyName.set(companyName);
    this._token.set(authData.token);
    this.setUser(authData.user);
    this._business.set(authData.business);
    this.permissions.set(authData.permissions);

    localStorage.setItem(AuthStorageKey.COMPANY_NAME, this._companyName()!);
    localStorage.setItem(AuthStorageKey.TOKEN, this._token()!);
    localStorage.setItem(AuthStorageKey.USER, JSON.stringify(this._user()));
    localStorage.setItem(
      AuthStorageKey.BUSINESS,
      JSON.stringify(this._business()),
    );
    localStorage.setItem(
      AuthStorageKey.PERMISSIONS,
      JSON.stringify(this.permissions()),
    );
  }

  private clearData(): void {
    for (const key of AUTH_SENSITIVE_KEYS) {
      localStorage.removeItem(key);
    }

    this._token.set(null);
    this.setUser(null);
    this._business.set(null);
    this.permissions.set(null);
  }
}
