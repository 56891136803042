import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OnyxOption } from '@onyx/angular';
import { forkJoin, map, Observable, of } from 'rxjs';
import { FleetCategory } from '../../dashboard/fleet/common/enums/fleet-category';
import {
  DictionaryCode,
  DictionaryValueByCode,
} from '../enums/dictionary-code';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class DictionariesService extends ApiService {
  constructor(
    protected override http: HttpClient,
    private translateService: TranslateService,
  ) {
    super(http);
  }

  public getDictionary<T extends DictionaryCode>(
    code: T,
  ): Observable<DictionaryValueByCode[T][]> {
    if (code === DictionaryCode.DRIVERS_LICENCE_CATEGORY) {
      return of([
        {
          name: 'B',
          value: 'b',
          requireDriverCard: false,
          requireProfessionalQualifications: false,
        },
        {
          name: 'B1',
          value: 'b1',
          requireDriverCard: false,
          requireProfessionalQualifications: false,
        },
        {
          name: 'B+E',
          value: 'be',
          requireDriverCard: false,
          requireProfessionalQualifications: false,
        },
        {
          name: 'C',
          value: 'c',
          requireDriverCard: true,
          requireProfessionalQualifications: true,
        },
        {
          name: 'C1',
          value: 'c1',
          requireDriverCard: true,
          requireProfessionalQualifications: false,
        },
        {
          name: 'C1+E',
          value: 'c1e',
          requireDriverCard: true,
          requireProfessionalQualifications: false,
        },
        {
          name: 'C+E',
          value: 'ce',
          requireDriverCard: true,
          requireProfessionalQualifications: true,
        },
      ] as any);
    }

    const language =
      this.translateService.currentLang ?? this.translateService.defaultLang;

    return this.get(`/dictionaries/${code}/${language}`);
  }

  public getFleetCategories(): Observable<OnyxOption<FleetCategory>[]> {
    return forkJoin([
      this.getDictionary(DictionaryCode.VEHICLE_CATEGORY),
      this.getDictionary(DictionaryCode.TRAILER_CATEGORY),
    ]).pipe(map((categories) => categories.flat()));
  }

  public getVatIdCountries(): Observable<OnyxOption<string>[]> {
    return this.getDictionary(DictionaryCode.COUNTRY).pipe(
      map((countries) =>
        countries.map((country) => ({
          ...country,
          name: country.value.toUpperCase(),
        })),
      ),
    );
  }
}
