import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router';
import { OnyxToastService, OnyxToastType } from '@onyx/angular';
import { captureException } from '@sentry/angular-ivy';
import { catchError, map, of } from 'rxjs';
import { DriverDto } from '../../../../drivers/common/interfaces/driver.dto';
import { FleetDto } from '../../../../fleet/common/interfaces/fleet.dto';
import { BaseForm } from '../../base-form/base-form.component';
import { BasesRoute } from '../../bases.routes';
import { BasesService } from '../services/bases.service';

export const editBaseResolver: ResolveFn<BaseForm | null> = (
  route: ActivatedRouteSnapshot,
) => {
  const baseService = inject(BasesService);
  const toastService = inject(OnyxToastService);
  const router = inject(Router);

  const uuid = route.paramMap.get('uuid')!;

  return baseService.getBase(uuid).pipe(
    map(
      (base): BaseForm => ({
        assignedDrivers: {
          selectedDrivers: base.assignedDrivers.map(
            // HOLD: replace if types will be ready
            (driver: DriverDto) => driver,
          ),
          drivers: base.assignedDrivers.map((driver: DriverDto) => ({
            driver: driver,
            uuid: driver.uuid,
          })),
        },
        assignedVehicles: {
          selectedVehicles: base.assignedVehicles.map(
            (vehicle: FleetDto) => vehicle,
          ),
          vehicles: base.assignedVehicles.map((vehicle: FleetDto) => ({
            vehicle: vehicle,
            uuid: vehicle.uuid,
          })),
        },
        baseInformation: {
          address: base.address,
          name: base.name,
        },
        contact: {
          phone: base.phone,
        },
        businessHours: {
          weekday: base.businessHours.weekday,
          saturday: base.businessHours.saturday,
          sunday: base.businessHours.sunday,
        },
      }),
    ),
    catchError((error: HttpErrorResponse) => {
      if (error.status === HttpStatusCode.BadRequest) {
        toastService.showError('bases.toasts.baseNotFound', {
          keepOnNavigation: true,
        });
      } else {
        toastService.showCustom(OnyxToastType.SERVER_ERROR, {
          keepOnNavigation: true,
        });
        captureException(error);
      }

      router.navigateByUrl(BasesRoute.BASES_LIST);
      return of(null);
    }),
  );
};
